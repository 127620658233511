<script lang="ts">
  export let text: string = '';
  export let labelClass: string = '';
  import { cn } from 'src/utils/cn';
</script>

<label class={cn('label pb-[20px]', `${labelClass}`)}>
  <div class="flex gap-1">
    <span class=" text-neutral-500 text-base font-bold {$$props.class} ">{text}</span>
    <slot name="right" />
  </div>
  <slot />
</label>
